import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { Scrollbars } from 'react-custom-scrollbars-2'
import App from './App'
import { GlobalStyle } from './GlobalStyle'
import { HttpLink } from '@apollo/client/link/http'

const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/messari/uniswap-v3-arbitrum',
  }),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <GlobalStyle />
    <Scrollbars>
      <App />
    </Scrollbars>
  </ApolloProvider>,
  document.getElementById('root')
)
