import styled from 'styled-components'
import { Pools } from './Pools'
import { Details } from './Details'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'

const ContentBodyWrapper = styled.div`
  padding: 1.4rem;
`

const ContentsWrapper = styled.div`
  padding-top: 1.4rem;
`

export const ContentBody: React.FC = () => {
  return (
    <ContentBodyWrapper>
      <ContentsWrapper>
        <Router>
          <Routes>
            <Route path="/" element={<Pools />} />
            <Route path={`/details/:id`} element={<Details />} />
          </Routes>
        </Router>
      </ContentsWrapper>
    </ContentBodyWrapper>
  )
}
