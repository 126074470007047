import ReactTooltip from 'react-tooltip'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import { BsDot } from 'react-icons/bs'
// @ts-expect-error
import abbreviate from 'number-abbreviate'
import { Token } from '../queries'
import './Pool.css'

export const Pool: React.FC<{
  id: String
  token0: Token
  token1: Token
  totalValueLockedUSD: string
  volumeUSD: string
  liquidity: string
  openPositionCount: string
  cumulativeSwapCount: string
  dailySnapshots: { day: string; totalLiquidity: string; dailyVolumeUSD: string }[]
}> = ({
  id,
  token0,
  token1,
  totalValueLockedUSD,
  volumeUSD,
  liquidity,
  openPositionCount,
  cumulativeSwapCount,
  dailySnapshots,
}) => { 
  
  return (

    <div className={`pool-wrapper`}>
      <div className="pair-symbol-wrapper">
        <div className="pair-symbols">
          <span data-tip data-for={token0.id} className="token-name">
            <ReactTooltip effect="solid" place="top" id={token0.id}>
              {token0.name}
            </ReactTooltip>
            {token0.symbol}
          </span>{' '}
          <span className="pair-seperator">
            <BsDot />
          </span>{' '}
          <span data-tip data-for={token1.id} className="token-name">
            <ReactTooltip effect="solid" place="top" id={token1.id}>
              {token1.name}
            </ReactTooltip>
            {token1.symbol}
          </span>
        </div>
      </div>

      <div className="info-section">
        <div className="info-wrapper">
          <div className="info-title">TVL</div>
          <div className="info-value">
            {'$'}
            {abbreviate(parseFloat(totalValueLockedUSD).toFixed(4), 4)}
          </div>
        </div>

        <div className="info-wrapper">
          <div className="info-title">Volume</div>
          <div className="info-value">
            {'$'}
            {abbreviate(parseFloat(volumeUSD).toFixed(4), 4)}
          </div>
        </div>

        <div className="info-wrapper">
          <div className="info-title">Open Pos. Count</div>
          <div className="info-value">
            {abbreviate(parseFloat(openPositionCount).toFixed(4), 4)}
          </div>
        </div>

        <div className="info-wrapper">
          <div className="info-title">Liquidity</div>
          <div className="info-value">
            {abbreviate(parseFloat(liquidity).toFixed(4), 4, ['k', 'm', 'b', 't', 'q'])}
          </div>
        </div>

        <div className="info-wrapper">
          <div className="info-title">Total Swap Count</div>
          <div className="info-value">
            {abbreviate(parseFloat(cumulativeSwapCount).toFixed(4), 4)}
          </div>
        </div>

        <div className="info-wrapper">
          <div className="info-title">Volume (24H)</div>
          <div className="info-value">
            {'$'}
            {abbreviate(parseFloat(dailySnapshots[0].dailyVolumeUSD).toFixed(4), 4)}
          </div>
        </div>
      </div>
    </div>
  )
}
