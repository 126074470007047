import ReactTooltip from 'react-tooltip'
import React, { useState } from 'react'
import { Token } from '../queries'
import { gql } from '@apollo/client'
// @ts-expect-error
import abbreviate from 'number-abbreviate'
import './Details.css'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {Pool} from '../queries'
import { Spinner } from './Spinner'
import { Chart, CategoryScale, LinearScale, LineElement, PointElement, Title, LineController } from "chart.js"
import { Line } from "react-chartjs-2"
Chart.register(CategoryScale, LineController, LineElement, PointElement, LinearScale, Title);


export const Details: React.FC = () => {
    const id = useParams();
    const query = gql`
    query MyQuery {
      liquidityPool(id: "${id.id}") {
        name
        symbol
        totalValueLockedUSD
        cumulativeVolumeUSD
        cumulativeSwapCount
        activeLiquidity
        openPositionCount
        dailySnapshots(first: 50, orderBy: day, orderDirection: desc) {
          day
          dailySwapCount
          dailyVolumeUSD
        }
        hourlySnapshots(first:24, orderBy: hour, orderDirection: desc) {
          hour
          hourlySwapCount
          hourlyVolumeUSD
        }
      }
    }`

    const { loading, data } = useQuery(query, {
      pollInterval: 1000 * 20,
      variables: { orderBy: 'volumeUSD' },
      nextFetchPolicy: 'cache-and-network',
    })

    const poolData = data?.liquidityPool
    const dailySnapshots = poolData?.dailySnapshots;
    const hourlySnapshots = poolData?.hourlySnapshots;


    return (
      <>
        {loading ? (
          <Spinner />
          ) : ( poolData && (
          <div className="wrapper">
            <div className="pool-title">{poolData.symbol}</div>
            <br></br>
            <div className="info-section">
              <div className="info-wrapper" id="info1">
                <div className="info-title">TVL</div>
                <div className="info-value">
                  {'$'}
                  {abbreviate(parseFloat(poolData.totalValueLockedUSD).toFixed(4), 4)}
                </div>
              </div>

              <div className="info-wrapper" id="info2">
                <div className="info-title">Volume</div>
                <div className="info-value">
                  {'$'}
                  {abbreviate(parseFloat(poolData.cumulativeVolumeUSD).toFixed(4), 4)}
                </div>
              </div>

              <div className="info-wrapper" id="info3">
                <div className="info-title">Open Pos. Count</div>
                <div className="info-value">
                  {abbreviate(parseFloat(poolData.openPositionCount).toFixed(4), 4)}
                </div>
              </div>

              <div className="info-wrapper" id="info4">
                <div className="info-title">Liquidity</div>
                <div className="info-value">
                  {abbreviate(parseFloat(poolData.activeLiquidity).toFixed(4), 4, ['k', 'm', 'b', 't', 'q'])}
                </div>
              </div>

              <div className="info-wrapper" id="info5">
                <div className="info-title">Total Swap Count</div>
                <div className="info-value">
                  {abbreviate(parseFloat(poolData.cumulativeSwapCount).toFixed(4), 4)}
                </div>
              </div>

              <div className="info-wrapper" id="info6">
                <div className="info-title">Volume (24H)</div>
                <div className="info-value">
                  {'$'}
                  {abbreviate(parseFloat(poolData.dailySnapshots[0].dailyVolumeUSD).toFixed(4), 4)}
                </div>
              </div>
            </div>
            
              
            <div>
              {/* <Line
                options = {{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    title: {
                      display: true,
                      text: "Daily Volume",
                    },
                  },
                }}
                data ={{
                  labels: dailySnapshots?.slice(0).reverse().map((snapshot: { day: any }) => (new Date(snapshot.day * 86400000)).toDateString().slice(4, 15)),
                  datasets: [
                    {
                      label: 'Volume (USD)',
                      data: dailySnapshots?.map((snapshot: { dailyVolumeUSD: any }) => snapshot.dailyVolumeUSD),
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 1,
                    },
                  ],
                }}
              />
              <Line
                options = {{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    title: {
                      display: true,
                      text: "Daily Swap Count",
                    },
                  },
                }}
                data ={{
                  labels: dailySnapshots?.slice(0).reverse().map((snapshot: { day: any }) => (new Date(snapshot.day * 86400000)).toDateString().slice(4, 15)),
                  datasets: [
                    {
                      label: 'Daily Swap Count',
                      data: dailySnapshots?.map((snapshot: { dailySwapCount: any }) => snapshot.dailySwapCount),
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 1,
                    },
                  ],
                }}
              /> */}
              <Line
                options = {{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    title: {
                      display: true,
                      text: "Hourly Volume ($)",
                    },
                  },
                }}
                data ={{
                  labels: hourlySnapshots?.slice(0).reverse().map((snapshot: { hour: any }) => {
                    const curr = new Date(snapshot.hour * 3600000);
                    return curr.toDateString().slice(4, 10) + " " + curr.getHours() + ":00";
                  }),
                  datasets: [
                    {
                      label: 'Daily Swap Count',
                      data: hourlySnapshots?.map((snapshot: { hourlyVolumeUSD: any }) => snapshot.hourlyVolumeUSD),
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 1,
                    },
                  ],
                }}
              />
              <Line
                options = {{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    title: {
                      display: true,
                      text: "Hourly Swap Count",
                    },
                  },
                }}
                data ={{
                  labels: hourlySnapshots?.slice(0).reverse().map((snapshot: { hour: any }) => {
                    const curr = new Date(snapshot.hour * 3600000);
                    return curr.toDateString().slice(4, 10) + " " + curr.getHours() + ":00";
                  }),
                  datasets: [
                    {
                      label: 'Daily Swap Count',
                      data: dailySnapshots?.map((snapshot: { dailySwapCount: any }) => snapshot.dailySwapCount),
                      backgroundColor: 'rgba(75, 192, 192, 0.2)',
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 1,
                    },
                  ],
                }}
              />
            </div>
          </div>
          )
        )}
      </>
    )
}