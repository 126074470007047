import { useQuery } from '@apollo/client'
import { PoolData, GET_POOL_DATA } from '../queries'
import { Pool } from './Pool'
import { Spinner } from './Spinner'
import { Link } from 'react-router-dom'

export const Pools: React.FC = () => {
  const { loading, data } = useQuery<PoolData>(GET_POOL_DATA, {
    pollInterval: 1000 * 20,
    variables: { orderBy: 'volumeUSD' },
    nextFetchPolicy: 'cache-and-network',
  })

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        data && (
          <>
            {data.liquidityPools
              //.slice(current * PAGE_SIZE, (current + 1) * PAGE_SIZE)
              .map(
                ({
                  id,
                  inputTokens,
                  totalValueLockedUSD,
                  cumulativeVolumeUSD,
                  activeLiquidity,
                  openPositionCount,
                  cumulativeSwapCount,
                  dailySnapshots,
                }) => (
                  <Link to={`/details/${id}`} key={id} style={{ color: 'inherit', textDecoration: 'none', padding: '40px'}}>
                    <Pool
                    id={id}
                    token0={inputTokens[0]}
                    token1={inputTokens[1]}
                    totalValueLockedUSD={totalValueLockedUSD}
                    volumeUSD={cumulativeVolumeUSD}
                    liquidity={activeLiquidity}
                    openPositionCount={openPositionCount}
                    cumulativeSwapCount={cumulativeSwapCount}
                    dailySnapshots={dailySnapshots}
                    />
                  </Link>
                )
              )}
          </>
        )
      )}
    </>
  )
}
