import { gql } from '@apollo/client'

export interface Token {
  id: string
  name: string
  symbol: string
  totalValueLockedUSD?: string
  totalSupply?: string
  volumeUSD?: string
  tokenDayData?: { priceUSD: string }[]
}

export interface Pool {
  id: string
  inputTokens: Token[]
  totalValueLockedUSD: string
  cumulativeVolumeUSD: string
  activeLiquidity: string
  openPositionCount: string
  cumulativeSwapCount: string
  dailySnapshots: { day: string; totalLiquidity: string; dailyVolumeUSD: string }[]
}

export interface PoolData {
  liquidityPools: Pool[]
}

export const GET_POOL_DATA = gql`
  query MyQuery {
    liquidityPools(first: 5, orderBy: cumulativeVolumeUSD, orderDirection: desc) {
      id
      name
      symbol
      inputTokens {
        id
        name
        symbol
        _totalValueLockedUSD
      }
      totalValueLockedUSD
      cumulativeVolumeUSD
      cumulativeSwapCount
      activeLiquidity
      openPositionCount
      dailySnapshots(first: 5, orderBy: day, orderDirection: desc) {
        day
        totalLiquidity
        dailyVolumeUSD
      }
    }
  }`
